import CartIcon from 'images/icons/cart-menu.svg';
import Logo from 'components/Logo';
import React from 'react';
import ReactModal from 'react-modal';
import SignOutIcon from 'images/icons/signout.svg';
import UserService from 'lib/UserService';
import styles from './styles.scss';
import { themr } from '@friendsofreactjs/react-css-themr';
import { useStore } from 'hooks/index';

interface Props {
  theme?: Theme;
  toggleMenu: () => void;
}

const UserMenu = ({ theme, toggleMenu }: Props) => {
  const { loggedIn, userProfile } = useStore('userStore');

  const onSignOut = () => {
    toggleMenu();
    UserService.logOutUser();
  };

  return (
    <ReactModal isOpen={true} className={theme!.userMenuModal}>
      <div className={theme!.logoContainer}>
        <Logo />
      </div>
      {!loggedIn ? (
        <div className={theme!.userMenu}>
          <a href="/signin">Sign In</a>
          {/* <a href="/signup">Become a member</a> */}
        </div>
      ) : (
        <div className={theme!.userMenu}>
          <a href="/profile">
            <div className={theme!.avatar}>{userProfile.firstName[0]}</div>
            <strong>{userProfile.firstName} {userProfile.lastName}</strong>
          </a>
          <div className={theme!.cart}>
            <CartIcon />&nbsp;<strong>Cart</strong>
          </div>
          <a href="/profile?step=1">Edit Profile</a>
          <a href="/profile?step=2">Manage Subscription</a>
          <a href="/profile?step=3">Track Orders</a>
          {userProfile.fbpid && (
            <a href="/profile?step=4">Payment</a>
          )}
          <button className={theme!.signout} onClick={onSignOut}>
            Sign Out
            <SignOutIcon />
          </button>
        </div>
      )}
    </ReactModal>
  );
};

export default themr('UserMenu', styles)(UserMenu);
