import * as React from 'react';
import cc from 'classcat';
import { themr } from '@friendsofreactjs/react-css-themr';

import styles from './styles.scss';

interface Props {
  links: any;
  theme?: Theme;
  activePath?: string;
  toggleMenu?: () => void;
  sub?: boolean;
}

const Links = ({ links, activePath, sub, toggleMenu, theme }: Props) => {
  const linkArray = links && links.map((link: any) => {
    if (Object.keys(link).length === 2) { // entry
      return {
        title: link.entry[0].title,
        link: `/${link.entry[0].slug}`
      };
    } else {
      return {
        title: link.linkTitle,
        link: link.linkUrl
      };
    }
  });

  const onClick = () => {
    toggleMenu && toggleMenu();
  };

  return (
    <div className={theme!.linkContainer}>
      <div className={cc({
        [theme!.links]: true,
        [theme!.sub]: sub
      })}>
        {linkArray.map((link: { title: string; link: string }) => (
          <a
            href={`${link.link}`}
            aria-current={activePath?.includes(link.link)}
            key={link.title}
            onClick={onClick}
          >
            {link.title}
          </a>
        ))}
      </div>
    </div>
  );
};

export default themr('Links', styles)(Links);
