import * as React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';

import styles from './styles.scss';

interface Props {
  title: string;
  links: (Link | Entry)[];
  theme?: Theme;
}

// TODO: URL STRUCTURE MAY CHANGE
const Links = ({ title, links, theme }: Props) => {
  const linkArray = links && links.map(link => {
    if ('entry' in link) { // entry
      return {
        title: link.entry[0].title,
        link: `/${link.entry[0].slug}`
      };
    } else {
      return {
        title: link.linkTitle,
        link: link.linkUrl
      };
    }
  });

  return (
    <div className={theme!.linkContainer}>
      <p>{title}</p>
      <div className={theme!.links}>
        {linkArray.map((link: { title: string; link: string}) => (
          <a href={`${link.link}`} key={link.title}>{link.title}</a>
        ))}
      </div>
    </div>
  );
};

export default themr('Links', styles)(Links);
