import React from 'react';
import Icon from 'images/icons/loading.gif';

import './styles.scss';

const Loading = () => (
  <div styleName="loading">
    <img src={Icon} alt="loading..." />
  </div>
);

export default Loading;
