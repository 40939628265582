import React from 'react';
import { useIsMobile } from 'hooks/index';
import { BreadcrumbList, Thing } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';

import HomeIcon from 'images/icons/home.svg';
import Chevron from 'images/icons/chevron.svg';

import './styles.scss';

interface Props {
  breadcrumbs: BreadcrumbItem[];
}

const Breadcrumbs = ({ breadcrumbs }: Props) => {
  const [isMobile] = useIsMobile(768);

  // Mobile breadcrumbs only show trails is longer than 1
  if (isMobile && breadcrumbs.length <= 1) {return null; };

  return (
    breadcrumbs.length ? (
      <div styleName="container">
        <div
          styleName="breadcrumbs"
        >
          <a href="/"><HomeIcon /></a>

          {breadcrumbs.map((b, i) => (
            <React.Fragment key={b.title}>
              <Chevron styleName="chevron" />
              {b.title && (
                i === breadcrumbs.length - 1 ? (
                  <span>{b.icon && <img src={b.icon} />}{b.title}</span>
                ) : (
                  <a href={b.path}>{b.icon && <img src={b.icon} />}{b.title}</a>
                )
              )}
            </React.Fragment>
          ))}

          <JsonLd<BreadcrumbList> item={{
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadcrumbs.map((b, i) => ({
              '@type': 'ListItem',
              position: i + 1,
              item: {
                '@type': b.type || 'ItemPage',
                '@id': b.path,
                name: b.title,
              } as Thing
            }))
          }} />
        </div>
      </div>
    ) : null
  );
};

export default Breadcrumbs;
