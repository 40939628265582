import React, { useState } from 'react';
import cc from 'classcat';
import { themr } from '@friendsofreactjs/react-css-themr';
import { useIsMobile } from 'hooks/index';

import TopMenu from './TopMenu';
import Nav from './Nav';
import Logo from 'components/Logo';
import MobileMenu from './MobileMenu';
import UserMenu from './UserMenu';

import UserIcon from 'images/icons/user.svg';

import styles from './styles.scss';


interface Props {
  data?: any;
  theme?: Theme;
  path: string;
}

const Header: React.SFC<Props> = ({ theme, path }: Props) => {
  const [ isMobile ] = useIsMobile(1024);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenu, toggleUserMenu] = useState(false);

  return (
    <>
      {userMenu && <UserMenu toggleMenu={() => toggleUserMenu(!userMenu)} />}
      <header className={theme!.header}>
        <div className={theme!.inner}>
          {!isMobile && (<TopMenu />)}
          <div className={theme!.logo}>
            <Logo />
          </div>
          {!isMobile && (<Nav path={path}/>)}
          <div styleName="mobile" id="mobileMenu">
            {isMobile && (
              <button
                styleName="loggedInButton"
                onClick={() => toggleUserMenu(!userMenu)}
                data-active={userMenu}
              >
                <UserIcon />
              </button>
            )}
            <button
              className={cc([theme!.mobileMenu, 'hamburger', 'hamburger--3dy', { 'is-active': menuOpen }])}
              onClick={() => setMenuOpen(!menuOpen)}
              type="button"
            >
              <span>{menuOpen ? 'Close' : 'Menu'}</span>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
            <MobileMenu isOpen={menuOpen} toggleMenu={() => setMenuOpen(!menuOpen)}/>
          </div>
        </div>
      </header>
    </>
  );
};

export default themr('Header', styles)(Header);
