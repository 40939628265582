import { captureException } from 'isomorphic-sentry';

export default class CSRF {
  static getCsrf = async () => {
    try {
      const session = await fetch('/actions/users/session-info', { headers: { 'Accept': 'application/json' } }).then(response => response.json());
      if (session?.csrfTokenValue) {
        return session.csrfTokenValue;
      }
      return '';
    } catch(e) {
      captureException(e);
      return '';
    }
  }
}
