import Csrf from './Csrf';
import { captureException } from 'isomorphic-sentry';
import { format } from 'date-fns';

export default class UserService {
  public static async loggedIn(){
    return await this.doRequest('/actions/users/session-info');
  }

  public static async loggedInSSR(clientCookies: string){
    return this.doRequestSSR('/actions/users/session-info', clientCookies);
  }

  public static async incomplete(){
    return await fetch('/user-api/incomplete').then(r => r.json());
  }

  public static async incompleteSSR(clientCookies: string){
    return this.doRequestSSR('/user-api/incomplete', clientCookies);
  }

  public static socialLogin(provider: string, redirect = '/') {
    return `/index.php?p=actions/social/login-accounts/login&provider=${provider}&redirect=${redirect}`;
  }

  public static loginUser(loginData: LoginParams) {
    return this.doRequest('/actions/users/login', loginData);
  }

  public static async createUser(creationData: UserCreationData) {
    return await this.doRequest('/user-api/create', creationData);
  }

  public static async updateUser(profileData: UserProfileData) {
    if (profileData.dob && typeof profileData.dob === 'object') {
      profileData.dob = format(profileData.dob, 'yyyy-MM-dd');
    }
    return await this.doRequest('/user-api/update', profileData);
  }

  public static async updatePaymentStatus(fuseBillStatus: { activePayment: string; accountStanding: string }) {
    return await this.doRequest('/user-api/update-active-status', fuseBillStatus);
  }

  public static async getOrder(orderId: string) {
    return await this.doRequest('/user-api/order', { orderId });
  }

  public static async getOrders() {
    return await this.doRequest('/user-api/orders');
  }

  public static async updateUserProfile(profileData: UserProfileData) {
    if (profileData.dob && typeof profileData.dob === 'object') {
      profileData.dob = format(profileData.dob, 'yyyy-MM-dd');
    }
    return await this.doRequest('/user-api/update-profile', profileData);
  }

  public static async updatePaymentInfo(paymentResponse: FuseBill.Response.CreatePayment) {
    return await this.doRequest('/user-api/updatePayment', paymentResponse);
  }

  public static async logOutUser() {
    await UserService.doRequest('/actions/users/logout');
    window.location.assign('/');
  }

  public static async getUserData() {
    return await this.doRequest('/user-api/user');
  }

  public static async getUserDataSSR(clientCookies: string) {
    return await this.doRequestSSR('/user-api/user', clientCookies);
  }

  public static async getCoupon() {
    return await this.doRequest('/api/coupon/getCoupon');
  }

  static async doRequestSSR (path: string, cookies: string) {
    const headers = new Headers({
      Accept: 'application/json',
      cookie: cookies,
      SameSite: 'true'
    });
    try {
      const response = await fetch(`http://${process.env.NGINX_HOST}${path}`,
        {
          method: 'GET',
          headers,
          credentials: 'same-origin'
        }).then(r => r.json());
      return response;
    } catch (error) {
      captureException(error);
      return error;
    }
  }

  public static async forgotPassword(userEmail: { loginName: string }) {
    return await this.doRequest('/user-api/forgot-password', userEmail);
  }

  public static async resetPassword(data: { code: string; id: string; newPassword: string }) {
    return await this.doRequest('/user-api/reset-password', data);
  }


  static async doRequest (path: string, data?: any) {
    const body = new FormData();
    const csrf = await Csrf.getCsrf();
    body.append('CRAFT_CSRF_TOKEN', csrf);
    if (data) {
      Object.keys(data).forEach(key => body.append(key, data[key] == null ? '' : data[key]));
    }
    const headers = new Headers({
      Accept: 'application/json',
      SameSite: 'true'
    });

    const server = typeof window !== 'undefined' ? window.location.host : process.env.NGINX_HOST;
    const protocol = typeof window !== 'undefined' && window.location.protocol || 'http:';
    try {
      const response = await fetch(`${protocol}//${server}${path}`,
        {
          method: 'POST',
          headers,
          body,
          credentials: 'same-origin'
        }).then(r => r.json());
      return response;
    } catch (error) {
      captureException(error);
      return false;
    }
  }
}
