import * as React from 'react';
import cc from 'classcat';
import { themr } from '@friendsofreactjs/react-css-themr';

import styles from './styles.scss';

interface BaseProps {
  children: (string | React.ReactNode)[] | string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  theme?: Theme;
  [key: string]: unknown;
}

interface WithOnClick {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  link?: never;
}

interface WithLink {
  onClick?: never;
  link: string;
}

interface SubmitOnly {
  onClick?: never;
  link?: never;
  type: 'submit';
}

type Props = BaseProps & (WithOnClick | WithLink | SubmitOnly);

const Button: React.SFC<Props> = ({
  children, type, disabled, onClick, link = '', theme, ...props
}: Props) => (
  onClick || type === 'submit' ? (
    <button
      className={cc({
        [theme!.Button]: true,
        [theme!.disabled]: disabled
      })}
      type={type}
      onClick={disabled ? () => {} : onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  ) : (
    <a
      className={cc({
        [theme!.Button]: true,
        [theme!.disabled]: disabled
      })}
      href={link}
      {...props}
    >
      {children}
    </a>
  )
);

Button.defaultProps = {
  type: 'button',
};

export default themr('Button', styles)(Button);
