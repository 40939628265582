import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { themr } from '@friendsofreactjs/react-css-themr';

import Links from '../Links';

import NavQuery from './query.graphql';

import styles from './styles.scss';

interface Props {
  theme?: Theme;
  path: string;
}

const Nav = ({ theme, path }: Props) => {
  const { data } = useQuery(NavQuery);
  return (
    <nav className={theme!.headerNav}>
      {data && data.categories.map((category: any) => (
        <Links key={category.title} activePath={path} links={category.linkTable} />
      ))}
    </nav>
  );
};

export default themr('Nav', styles)(Nav);
