import React from 'react';
import DatePicker from 'react-datepicker';
import { themr } from '@friendsofreactjs/react-css-themr';

import CalendarIcon from 'images/icons/calendar.svg';

import styles from './styles.scss';

interface Props {
  callback: Function;
  endDate?: Date;
  name: string;
  placeholder: string;
  startDate?: Date;
  theme?: Theme;
  value: Date;
  width?: string;
}

const DateInput = ({ callback, endDate, name, placeholder, theme, value, width='50%' }: Props) => {
  return (
    <span className={theme!.datePicker} style={{ width: `${width}`}}>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        dropdownMode="select"
        maxDate={endDate}
        name={name}
        onChange={(date: Date) => callback(date, name)}
        placeholderText={placeholder}
        selected={typeof value === 'object' ? value : undefined}
        showMonthDropdown
        showYearDropdown
      />
      <CalendarIcon/>
    </span>
  );
};

export default themr('DateInput', styles)(DateInput);
