import React, { useState } from 'react';

import './styles.scss';

interface Props {
  text: string;
  name: string;
  callback: () => void;
  required?: boolean;
}

const Checkbox = ({ text, name, required, callback }: Props) => {
  const [checked, setChecked] = useState(false);

  const toggleCheckbox = () => {
    setChecked(!checked);
    callback();
  };

  return (
    <label htmlFor={name} styleName="checkboxContainer">
      <div styleName="checkbox" data-checked={checked} />
      <input name={name} type="checkbox" onChange={toggleCheckbox} required={required} />
      <span>
        {text}
      </span>
    </label>
  );
};

export default Checkbox;
