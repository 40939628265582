/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import cc from 'classcat';
import { themr } from '@friendsofreactjs/react-css-themr';
import { useQuery } from '@apollo/react-hooks';
import { useStore, useIsMobile } from 'hooks/index';

import Curve from 'images/layout/banner-curve.svg';
import FacebookIcon from 'images/icons/facebook.svg';
import InstagramIcon from 'images/icons/instagram.svg';
import TwitterIcon from 'images/icons/twitter.svg';
import YouTubeIcon from 'images/icons/youtube.svg';
import MapPinIcon from 'images/icons/map-pin.svg';
import PhoneIcon from 'images/icons/phone.svg';

import Links from './Links';
import Logo from 'components/Logo';
import footerQuery from './query.graphql';

import styles from './styles.scss';
interface Props {
  theme?: Theme;
  path: string;
}

const Footer = ({ theme, path }: Props) => {
  const [isMobile] = useIsMobile(1024);
  function getPath (path: string) {
    const arr = path.split('/');
    if (arr.length < 2 || !arr[1]) {
      return '';
    } else if (arr.length > 2 && arr[1] === 'offers') {
      return arr[2];
    }
    return arr[1];
  }
  const globals = useStore('globals');
  const { data } = useQuery(footerQuery, { variables: { slug: getPath(path) }});
  let curveColour;
  if (data && data.page.length) {
    curveColour = data.page[0].curveColour;
  } else if (data && data.category.length) {
    curveColour = data.category[0].curveColour;
  }

  if (isMobile && ['/register', '/profile', '/cart'].includes(path)) {
    return null;
  }

  return (
    <footer
      className={cc({
        [theme!.footer]: true,
      })}
    >
      {path === '/' && (
        <div styleName="curve">
          <Curve fill={curveColour || "#f1fafb"} />
        </div>
      )}
      <div className={theme!.container}>
        <div className={theme!.top}>
          <div className={theme!.left}>
            <div className={theme!.inner}>
              <Logo />
              <div className={theme!.social}>
                {globals.facebook && (
                  <a href={globals.facebook}>
                    <FacebookIcon />
                  </a>
                )}
                {globals.instagram && (
                  <a href={globals.instagram}>
                    <InstagramIcon />
                  </a>
                )}
                {globals.youtube && (
                  <a href={globals.youtube}>
                    <YouTubeIcon />
                  </a>
                )}
                {globals.twitter && (
                  <a href={globals.twitter}>
                    <TwitterIcon />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className={theme!.right}>
            <div className={theme!.inner}>
              {data && data.categories.map((category: any) => (
                <Links key={category.title} title={category.title} links={category.linkTable} />
              ))}
              <div className={theme!.contact}>
                <p>GET IN TOUCH</p>
                {globals.phone && (
                  <a href={`tel:${globals.phone}`}>
                    <PhoneIcon /> Call us on <strong>{globals.phone}</strong>
                  </a>
                )}
                {globals.address && (
                  <a
                    href={`https://maps.google.com?q=:${globals.address?.address}`}
                    className={theme!.address}
                  >
                    <MapPinIcon />
                    <strong>
                      {globals.address.address.split(',').map((line: string) => <p key={line}>{line}</p>)}
                    </strong>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={theme!.copyright}>
        <div className={theme!.inner}>
          <div>
          Copyright &copy; {new Date().getFullYear()} API Leisure & Lifestyle {isMobile && <br/>}(WA) / All rights reserved.
          </div>
          <div className={theme!.links}>
            <a href="/sitemap">Sitemap</a>
            {isMobile && (<span>&nbsp;|&nbsp;</span>)}
            <a href="/terms-and-conditions">Terms &amp; Conditions</a>
          </div>
          <div className={theme!.leagueCredit}>
            <a href="https://league.agency" target="_blank" rel="noopener">Website by <strong>LEAGUE</strong></a>
          </div>
        </div>
      </div>
    </footer>
  );};

export default themr('Footer', styles)(Footer);
