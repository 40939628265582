import React from 'react';
import { observer } from 'mobx-react';
import { themr } from '@friendsofreactjs/react-css-themr';

import { useStore } from 'hooks/index';

import PhoneIcon from 'images/icons/phone.svg';

import styles from './styles.scss';

interface Props {
  theme?: Theme;
}

const MobileCTABar = ({ theme }: Props) => {
  const globals = useStore('globals');
  const userStore = useStore('userStore');

  return (
    <div>
      {!userStore.loggedIn && (
        <div className={theme!.mobileCtaBar}>
          <a className={theme!.phone} href={`tel:${globals.phone}`}>
            <PhoneIcon />
            Call us now
          </a>
          {/* <a className={theme!.join} href="/signup">
            Join Now
          </a> */}
        </div>
      )
      }
    </div>
  );
};

export default themr('MobileCTABar', styles)(observer(MobileCTABar));
