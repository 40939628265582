import React, { useRef, useState } from 'react';
import { useOnClickOutside, useStore } from 'hooks/index';

import BillingIcon from 'images/icons/tax.svg';
import CartIcon from 'images/icons/cart.svg';
import Chevron from 'images/icons/chevron.svg';
import Person from 'images/icons/person.svg';
import SignOut from 'images/icons/signout.svg';
import UserService from 'lib/UserService';
import { observer } from 'mobx-react';
import styles from './styles.scss';
import { themr } from '@friendsofreactjs/react-css-themr';

interface Props {
  theme?: Theme;
}

const TopMenu = ({ theme }: Props) => {
  const userStore = useStore('userStore');
  const { cartNumber } = useStore('cart');
  const [showMenu, setShowMenu] = useState(false);
  const userMenuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(userMenuRef, () => setShowMenu(false));

  return (
    <div className={theme!.topMenu}>
      {userStore.loggedIn ? (
        <>
          <button className={theme!.profile} onClick={() => setShowMenu(!showMenu)}>
            {userStore.userProfile?.firstName ? (
              <><div className={theme!.avatar}>{userStore.userProfile.firstName[0]}</div>{userStore.userProfile?.firstName} {userStore.userProfile?.lastName}</>
            )
              : 'Profile'}
            <Chevron />
          </button>
          <a className={theme!.cart} href="/cart">
            <CartIcon />
            Cart&nbsp;{cartNumber !== 0 && <div className={theme!.avatar}>{cartNumber}</div>}
          </a>
          {showMenu && (
            <div className={theme!.userMenu} ref={userMenuRef}>
              <h3 className={theme!.account}><Person /><a href="/profile">Your Account</a></h3>
              <a href="/profile?step=1">Edit Profile</a>
              <h3 className={theme!.billing}><BillingIcon />Billing</h3>
              <a href="/profile?step=2">Subscription</a>
              <a href="/profile?step=3">Track Order</a>
              {userStore.userProfile?.fbpid && <a href="/profile?step=4">Payment</a>}
              <button className={theme!.signout} onClick={UserService.logOutUser}>Sign Out<SignOut /></button>
            </div>
          )}
        </>
      ) : (
        <div className={theme!.signin}>
          <Person />
          <a href="/signin">Sign In</a>&nbsp;
          {/* |&nbsp;
          <div className={theme!.signUp}>Not a member yet?&nbsp;<a href="/signup">Sign up NOW!</a></div> */}
        </div>
      )
      }
    </div>
  );
};

export default themr('TopMenu', styles)(observer(TopMenu));
