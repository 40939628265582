import React from 'react';
import  { themr } from '@friendsofreactjs/react-css-themr';
import { useStore } from 'hooks/index';

import styles from './styles.scss';

const Logo = ({ theme }: { theme?: Theme }) => {
  const globals = useStore('globals');
  const logo = globals && globals.logo ? globals.logo : null;
  const siteTitle = globals && globals.siteTitle ? globals.siteTitle : 'Rewards';
  return (
    <div className={theme!.logo}>
      {logo && (<a href="/"><img src={logo} /></a>)}
      {!logo && siteTitle && (<a href="/">{siteTitle}</a>)}
    </div>
  );
};

export default themr('Logo', styles)(Logo);
