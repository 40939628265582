import React from 'react';
import { themr } from '@friendsofreactjs/react-css-themr';

import styles from './styles.scss';

interface Props {
  callback: Function;
  defaultValue?: string | number;
  fullWidth?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  theme?: Theme;
  title: string;
  type: string;
  value?: string | number;
  width?: string;
  children?: any;
  [key: string]: unknown;
}


const Input = ({ callback, children, defaultValue, fullWidth, name, placeholder, required, theme, title, type, value, width, ...additionalInputProps }: Props) => (
  <label
    htmlFor={name}
    className={`${theme!.animate} ${fullWidth && theme!.fullWidth}`}
    style={{ width: `${width}`}}
  >
    <input
      data-empty={!value}
      name={name}
      onChange={(e) => callback(e)}
      placeholder={placeholder}
      required={required}
      type={type}
      value={value || undefined}
      defaultValue={defaultValue || undefined}
      {...additionalInputProps}
    />
    <span>{title}</span>
    {children}
  </label>
);

export default themr('Input', styles)(Input);
