import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { themr } from '@friendsofreactjs/react-css-themr';
import ReactModal from 'react-modal';

import Logo from 'components/Logo';
import Links from '../Links';

import NavQuery from './query.graphql';

import styles from './styles.scss';

ReactModal.setAppElement('body');

interface Props {
  theme?: Theme;
  toggleMenu?: () => void;
  isOpen: boolean;
}

const MobileMenu = ({ theme, isOpen, toggleMenu }: Props) => {
  const { data } = useQuery(NavQuery);
  return (
    <ReactModal isOpen={isOpen} className={theme!.mobileMenuModal}>
      <nav className={theme!.mobileMenu}>
        <div className={theme!.logoContainer}>
          <Logo />
        </div>
        {data && data.header?.map((category: any) => (
          <Links key={category.title} links={category.linkTable} toggleMenu={toggleMenu}/>
        ))}
        <div styleName="sublinks">
          {data && data.categories?.map((category: any) => (
            <Links key={category.title} links={category.linkTable} toggleMenu={toggleMenu} sub/>
          ))}
        </div>
      </nav>
    </ReactModal>
  );
};

export default themr('MobileMenu', styles)(MobileMenu);
